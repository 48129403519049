.home-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  &.playing {
    .background-layer {
      opacity: 0;
    }
    .content-layer {
      display: none;
    }
  }
  // 初始动画
  &.landing {
    .tab-content {
      animation: 2s ease 0s content-landing;
    }
    .background-layer {
      // &::before {
      //   animation: 2s linear 0s bg-landing, 80s linear 0s infinite bg-layer-animation;
      // }
      .upper-layer {
        animation: 2s linear 0s bg-landing;
      }
    }
    .left-rect {
      left: -350px;
      animation: 1s linear 0s left-rect-landing;
    }
    .right-rect {
      right: -350px;
      animation: 1s linear 0s right-rect-landing;
    }
  }
  .background-layer {
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    // &::before {
    //   content: '';
    //   width: 150%;
    //   height: 300%;
    //   background-image: url('../../assets/images/home/bg_layer.png');
    //   position: absolute;
    //   transform: rotateZ(-20deg) translate(0, -400px);
    // }
    .layer-container {
      width: 150%;
      transform: rotateZ(-20deg) translate(0, -10%);
      position: absolute;
      .upper-layer,
      .bottom-layer,
      .additional-layer {
        width: 100%;
        display: block;
      }
      .additional-layer {
        position: absolute;
        left: 0;
      }
      .additional-layer-before {
        top: -50%;
      }
      .additional-layer-after {
        bottom: -50%;
      }
    }
  }
  .left-rect {
    width: 300px;
    height: 300px;
    background: linear-gradient(
      135deg,
      rgba(50, 251, 252, 1) 0%,
      rgba(50, 20, 242, 1) 100%
    );
    border-radius: 40px;
    opacity: 0.5;
    position: absolute;
    left: -215px;
    bottom: 90px;
    transform: rotateZ(-45deg);
    z-index: 3;
  }
  .right-rect {
    width: 300px;
    height: 300px;
    background: linear-gradient(
      135deg,
      rgba(50, 251, 252, 1) 0%,
      rgba(49, 86, 255, 1) 100%
    );
    border-radius: 40px;
    opacity: 0.5;
    position: absolute;
    right: -215px;
    top: 90px;
    transform: rotateZ(135deg);
    z-index: 3;
  }
  .content-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/images/home/text_decoration.png");
      // background-size: 52.6%;
      background-size: 1011px;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 1;
      opacity: 0.05;
    }
    .content {
      &.active {
        display: block;
        animation: none;
      }
      .tab-content {
        min-height: 324px;
      }
      display: none;
      
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 2;
      .slogon {
        font-size: 66px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        color: #fff;
        line-height: 66px;
        margin-bottom: 20px;
        letter-spacing: -1px;
      }
      .slogon-en {
        height: 18px;
        font-size: 18px;
        font-family: Montserrat-Regular;
        font-weight: 400;
        color: #bdbfc3;
        line-height: 18px;
        letter-spacing: 0;
      }
      .divider {
        height: 129px;
        position: relative;
        &::before {
          content: "";
          width: 48px;
          height: 2px;
          background: rgba(144, 147, 153, 1);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: none;
        }
      }
      .web-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 49px auto 32px;
        width: 324px;
        height: 48px;
        background: rgba(228, 231, 237, 1);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        cursor: pointer;
      }
      .downloadButton {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 384px;
        margin: 0 auto;
        .buttonType {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            background: rgba(228, 231, 237, 1);
            border-radius: 50%;
            margin-bottom: 10px;
            cursor: pointer;
            i {
              display: block;
              width: 32px;
              height: 32px;
              background-size: cover;
            }
            &.apple {
              i {
                background-image: url("../../assets/images/home/apple.png");
              }
            }
            &.android {
              i {
                background-image: url("../../assets/images/home/android.png");
              }
            }
            &.macOS {
              i {
                background-image: url("../../assets/images/home/apple.png");
              }
            }
            &.windows {
              i {
                background-image: url("../../assets/images/home/windows.png");
              }
            }
          }
          p {
            font-size: 14px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
          }
          .qrCode {
            width: 152px;
            height: 152px;
            background-color: #fff;
            background-image: url("../../assets/images/home/qr_android.png");
            background-size: 80% 80%;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 8px;
            position: absolute;
            left: -48px;
            top: 100px;
            z-index: 2;
            opacity: 0;
          }
          .macQRCode {
            background-image: url("../../assets/images/home/qr_ios.png");
          }
          &:hover {
            .qrCode {
              opacity: 1;
              transition: opacity 0.5s;
            }
          }
        }
      }
    }
    .mouse-icon {
      width: 32px;
      height: 50px;
      border-radius: 16px;
      border: 1px solid #fff;
      position: absolute;
      left: 50%;
      bottom: 40px;
      transform: translateX(-50%);
      &::after {
        content: "";
        width: 4px;
        height: 4px;
        background: #fff;
        border-radius: 2px;
        position: absolute;
        left: 14px;
        bottom: 31px;
      }
    }
  }

  .timePlan {
    box-sizing: border-box !important;
    padding-top: 85px !important;
    .downloadButton {
      width: 250px !important;
    }
    .timeplan-tips {
      margin-top: 20px;
      font-size: 14px;
      color: #FFF;
    }
  }
}
// 内容淡入动画
@keyframes content-landing {
  0% {
    opacity: 0;
    top: calc(50% + 30px);
  }
  100% {
    top: 50%;
    opacity: 1;
  }
}
// 背景淡入动画
@keyframes bg-landing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// 背景运动动画
@keyframes bg-layer-animation {
  0% {
    transform: rotateZ(-20deg) translate(0, -400px);
  }
  100% {
    transform: rotateZ(-20deg) translate(0, -1600px);
  }
}
// 左侧矩形消失动画
@keyframes left-rect-landing {
  0% {
    left: -215px;
  }
  100% {
    left: -350px;
  }
}
// 右侧矩形消失动画
@keyframes right-rect-landing {
  0% {
    right: -215px;
  }
  100% {
    right: -350px;
  }
}
