.not-found {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  .small-circle {
    width:155px;
    height:155px;
    background:linear-gradient(135deg,rgba(50,251,252,1) 0%,rgba(49,86,255,1) 100%);
    border-radius:93px;
    opacity:0.1;
    position: absolute;
    left: 170px;
    top: 180px;
  }
  .big-circle {
    width:493px;
    height:364px;
    background:linear-gradient(135deg,rgba(50,251,252,1) 0%,rgba(49,86,255,1) 100%);
    border-radius:323px;
    opacity:0.1;
    position: absolute;
    bottom: -182px;
    right: -246px;
  }
  .content {
    width: 730px;
    height: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    .icon {
      display: inline-block;
      width: 64px;
      height: 64px;
      background-image: url('../../assets/images/404/ic_search@2x.png');
      background-size: cover;
    }
    .desc-zh {
      height:84px;
      margin: 29px 0 15px 0;
      font-size:60px;
      font-family:Montserrat-Regular;
      font-weight:400;
      color:rgba(33,33,33,1);
      line-height:73px;
      letter-spacing: 0;
    }
    .desc-en {
      height:29px;
      margin-bottom: 50px;
      font-size:19px;
      font-family:Montserrat-Light;
      font-weight:300;
      color:rgba(102,102,102,1);
      line-height:29px;
      letter-spacing: 0;
    }
    .back-home {
      display: inline-block;
      width:236px;
      height:48px;
      background:rgba(75,162,243,1);
      border-radius:4px;
      line-height: 48px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
  }
}